<template>
  <div class="container-fluid my-2 content">
    <div
      class="text-center"
      v-if="getOrders.length == 0 && !$apollo.queries.getOrders.loading"
    >
      <h1>لايوجد لديك طلبات حتى الان</h1>
    </div>
    <!-- <cards v-if="getOrders.length > 0" :rows="getOrders" :columns="columns"> -->
    <!-- <template #name="nameProps"> -->
    <!-- {{ nameProps.row.name }} -->
    <!-- </template> -->

    <!-- <template #actions="actionsProps"> -->
    <!-- <div class="text-center my-2"> -->
    <!-- <button class="btn bwj-btn btn-md" @click="OrderInfo(order._id)"> -->
    <!-- التفاصيل -->
    <!-- </button> -->
    <!-- </div> -->
    <!-- </template> -->
    <!-- </cards> -->

    <!-- <div
      class="scrapiz-card mr-3 mb-3"
      style="width: 25rem"
      v-for="order in getOrders"
      v-if="getOrders"
    >
      <div
        class="scrapiz-card-header row"
        style="margin-left: 0 !important; margin-right: 0 !important"
      >
        <h6 class="col-7 text-right">
          تاريخ الطلب:{{ OrderDate(order.createdAt) }}
        </h6>
        <h6 class="col-5 text-left">
          <span
            class="badge align-middle"
            :class="badgeClass(order.status)"
            style="height=1.4rem;"
          >
            <div>{{ OrderStatus(order.status) }}</div>
          </span>
        </h6>
      </div>
      <img
        rel="preload"
        :src="'https://images.scrapiz.com/' + order.part.images[0]"
        class="scrapiz-card-img-top img-thumbnail"
        style="height: 15rem"
      />
      <div class="scrapiz-card-body">
        <div class="row">
          <h5 class="scrapiz-card-text text-right col-6">
            السيارة:{{ order.part.manufacturer }}
          </h5>
          <h5 class="scrapiz-card-text text-left col-6">
            القطعة:{{ order.part.name }}
          </h5>
        </div>
        <div class="row d-flex justify-content-center mt-3 align-items-end">
          <button class="btn bwj-btn btn-md" @click="OrderInfo(order._id)">
            التفاصيل
          </button>
        </div>
      </div>
    </div> -->

    <cards v-if=" getOrders.length > 0" :rows="getOrders"
      :columns="columns" 
      :cardSlots="cardSlots"
      >
<template #orderHeader="orderHeaderProps">

  <div
  class="mt-2 row"
  style="margin-left: 0 !important; margin-right: 0 !important"
>
  <h6 class="col-7 text-right">
    تاريخ الطلب:{{ OrderDate(orderHeaderProps.row.createdAt) }}
  </h6>
  <h6 class="col-5 text-left">
    <span
      class="badge align-middle"
      :class="badgeClass(orderHeaderProps.row.status)"
      style="height=1.4rem;"
    >
      <div>{{ OrderStatus(orderHeaderProps.row.status) }}</div>
    </span>
  </h6>
</div>
</template >
      <template #Images="imagesProps">
        <div class="row justify-content-around">

          <img
          rel="preload"
          :src="'https://images.scrapiz.com/' + imagesProps.row.part.images[0]"
          class="scrapiz-card-img-top img-thumbnail"
          style="height: 15rem"
        />
      
        </div>
      </template>

      <template #totalAmount="totalAmountProps">
        <div >
          <div class="row justify-content-around">
            <p class="col-5 m-auto">
              <slot>الإجمالي</slot>
            </p>
            <p class="col-5 m-auto" >
              <slot>         {{ totalAmountProps.row.partPrice + totalAmountProps.row.shippingPrice + totalAmountProps.row.codCharge }} ريال
              </slot>
            </p>
          </div>
      
        </div>
      </template>
      <template #actions="actionsProps">
        <button class="btn bwj-btn btn-md mb-2" @click="OrderInfo(actionsProps.row._id)">
          التفاصيل
        </button>
      </template>
    </cards>

  </div>
</template>
<script>

import { GET_ORDERS } from "../graphql/queries";
import cards from "../components/cards/cards.vue";
import {  getCatrgories,
  getFillterdCatrgories, getModels, getFillterdModels } from "../_helpers/index";


export default {
  created() {},
  data() {
    return {

      columns: [
        {
          key: ".part.name",
          label: "القطعة",
        },
        {
          key: ".part.model",
          label: "السيارة",
        },
     
       
      ],
    
      getOrders: [],
      cardSlots: {
        headerSlots: ["orderHeader"],
      topSlots:  ["Images"],
      bottomSlots:  ["totalAmount", "actions"]
      },

    };
  },
  apollo: {
    getOrders: {
      query: GET_ORDERS,
      result(result) {
        this.$store.commit("glopal/isLoading", false);
      },
    },
  },
  components: {

    cards,
  },
  methods: {
    total(rowObj) {
      return rowObj.shippingPrice + rowObj.partPrice;
    },
    OrderDate(date) {
      var d = new Date(parseInt(date));
      let day = d.getDate();

      let month = d.getMonth() + 1;
      let year = d.getFullYear();
      console.log(year);
      return day + "/" + month + "/" + year;
    },
    OrderStatus(status) {
      if (status == 1) {
        return "تم انشاء الطلب";
      } else if (status == 2) {
        return "تم الشحن";
      } else if (status == 3) {
        return "تم الإستلام";
      } else {
        return "ملغى";
      }
    },
    OrderInfo(id) {
      this.$router.push("/OrderDetails/" + id);

      return;
    },
    badgeClass(status) {
      if (status == 1) {
        return "badge-success";
      } else if (status == 2) {
        return "badge-success";
      } else if (status == 3) {
        return "badge-info";
      } else {
        return "badge-dark";
      }
    },
   
  },
  computed: {},
};
</script>

<style>
img {
  max-width: 100%;
  object-fit: contain;
}
</style>
